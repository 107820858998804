import React, { useEffect } from "react";
import { useIntl } from "gatsby-plugin-intl";

import "../styles/pages/Market.scss";

import MarketImage1 from "../assets/images/market-image-1.png";
import MarketImage2 from "../assets/images/market-image-2.png";
import MarketImage3 from "../assets/images/market-image-3.png";
import MarketImage4 from "../assets/images/market-image-4.png";
import MarketImage5 from "../assets/images/market-image-5.png";
import MarketImage6 from "../assets/images/market-image-6.png";
import MarketImage7 from "../assets/images/market-image-7.png";

import Layout from "../components/Layout.jsx";
import Seo from "../components/Seo";

export default function Platform() {
  const intl = useIntl();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleScroll = (id) => () => {
    const element = document.getElementById(id);
    element.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <Layout>
      <Seo title={intl.formatMessage({ id: "market-title" })} />
      <div className="market">
        <section className="market__hero">
          <h1 className="market__hero-title">{intl.formatMessage({ id: "market-title" })}</h1>
          <div className="market__hero-tabs">
            <button type="button" onClick={handleScroll("exchange")} className="market__hero-tab">
              <p className="market__hero-tab-text">{intl.formatMessage({ id: "market-tab1" })}</p>
            </button>
            <button type="button" onClick={handleScroll("index")} className="market__hero-tab">
              <p className="market__hero-tab-text">{intl.formatMessage({ id: "market-tab2" })}</p>
            </button>
            <button type="button" onClick={handleScroll("actions")} className="market__hero-tab">
              <p className="market__hero-tab-text">{intl.formatMessage({ id: "market-tab3" })}</p>
            </button>
            <button type="button" onClick={handleScroll("cryptocurrency")} className="market__hero-tab">
              <p className="market__hero-tab-text">{intl.formatMessage({ id: "market-tab4" })}</p>
            </button>
            <button type="button" onClick={handleScroll("rawmaterials")} className="market__hero-tab">
              <p className="market__hero-tab-text">{intl.formatMessage({ id: "market-tab5" })}</p>
            </button>
            <button type="button" onClick={handleScroll("bonus")} className="market__hero-tab">
              <p className="market__hero-tab-text">{intl.formatMessage({ id: "market-tab6" })}</p>
            </button>
            <button type="button" onClick={handleScroll("etfs")} className="market__hero-tab">
              <p className="market__hero-tab-text">{intl.formatMessage({ id: "market-tab7" })}</p>
            </button>
          </div>
          <div id="exchange" className="market__section">
            <div className="market__section-content">
              <div className="market__section-left">
                <h2 className="market__section-title">{intl.formatMessage({ id: "market-tab1" })}</h2>
                <p className="market__section-text">
                  {intl.formatMessage({ id: "market-text1" })}
                </p>
              </div>
              <div className="market__section-right">
                <img src={MarketImage1} alt="" className="market__section-image" />
                <p className="market__section-subtitle">{intl.formatMessage({ id: "market-tab1" })}</p>
              </div>
            </div>
          </div>
        </section>
        <section id="index" className="market__section">
          <div className="market__section-content market__section-content--reverse">
            <div className="market__section-left market__section-left--reverse">
              <h2 className="market__section-title">{intl.formatMessage({ id: "market-tab2" })}</h2>
              <p className="market__section-text">
                {intl.formatMessage({ id: "market-text2" })}
              </p>
            </div>
            <div className="market__section-right">
              <img src={MarketImage2} alt="" className="market__section-image" />
              <p className="market__section-subtitle market__section-subtitle--reverse">{intl.formatMessage({ id: "market-tab2" })}</p>
            </div>
          </div>
        </section>
        <section id="actions" className="market__section market__section--background">
          <div className="market__section-content">
            <div className="market__section-left">
              <h2 className="market__section-title">{intl.formatMessage({ id: "market-tab3" })}</h2>
              <p className="market__section-text">
                {intl.formatMessage({ id: "market-text3" })}
              </p>
            </div>
            <div className="market__section-right">
              <img src={MarketImage3} alt="" className="market__section-image" />
              <p className="market__section-subtitle">{intl.formatMessage({ id: "market-tab3" })}</p>
            </div>
          </div>
        </section>
        <section id="cryptocurrency" className="market__section">
          <div className="market__section-content market__section-content--reverse">
            <div className="market__section-left market__section-left--reverse">
              <h2 className="market__section-title">{intl.formatMessage({ id: "market-tab4" })}</h2>
              <p className="market__section-text">
                {intl.formatMessage({ id: "market-text4" })}
              </p>
            </div>
            <div className="market__section-right">
              <img src={MarketImage4} alt="" className="market__section-image" />
              <p className="market__section-subtitle market__section-subtitle--reverse">{intl.formatMessage({ id: "market-tab4" })}</p>
            </div>
          </div>
        </section>
        <section id="rawmaterials" className="market__section market__section--background">
          <div className="market__section-content">
            <div className="market__section-left">
              <h2 className="market__section-title">{intl.formatMessage({ id: "market-tab5-1" })}</h2>
              <p className="market__section-text">
                {intl.formatMessage({ id: "market-text5" })}
              </p>
            </div>
            <div className="market__section-right">
              <img src={MarketImage5} alt="" className="market__section-image" />
              <p className="market__section-subtitle">{intl.formatMessage({ id: "market-tab5-2" })}</p>
            </div>
          </div>
        </section>
        <section id="bonus" className="market__section">
          <div className="market__section-content market__section-content--reverse">
            <div className="market__section-left market__section-left--reverse">
              <h2 className="market__section-title">{intl.formatMessage({ id: "market-tab6" })}</h2>
              <p className="market__section-text">
                {intl.formatMessage({ id: "market-text6" })}
              </p>
            </div>
            <div className="market__section-right">
              <img src={MarketImage6} alt="" className="market__section-image" />
              <p className="market__section-subtitle market__section-subtitle--reverse">{intl.formatMessage({ id: "market-tab6" })}</p>
            </div>
          </div>
        </section>
        <section id="etfs" className="market__section market__section--background">
          <div className="market__section-content">
            <div className="market__section-left">
              <h2 className="market__section-title">{intl.formatMessage({ id: "market-tab7" })}</h2>
              <p className="market__section-text">
                {intl.formatMessage({ id: "market-text7" })}
              </p>
            </div>
            <div className="market__section-right">
              <img src={MarketImage7} alt="" className="market__section-image" />
              <p className="market__section-subtitle">{intl.formatMessage({ id: "market-tab7" })}</p>
            </div>
          </div>
        </section>
      </div>
    </Layout>
  );
}
